import useDateField from "@/composables/dateField.js";import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import {
  AUTO_COMPLETE_NO_DATA,
  DROPDOWN_NO_DATA,
} from "@/constants/displayTexts";
import DialogComponent from "@/modules/gcmsmain/admin/submenu/components/SignatureUpdateDialog.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";
import { RBAC_CONTROLLER } from "../../../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../../../stores/userRoleBasedAccess";
export default {
  name: "ManageRailroadCompanies",
  components: {
    OnlineHelpPOC,
    FeatureTable,
    DialogComponent,
    PrimaryButton,
    VueAutoComplete,
    LoadingOverlay,
  },
  setup() {
    const { getIdentifierIcon } = useIconsFunctions();
    const router = useRouter();
    const lookupData = ref(null);
    const railRoadList = ref([]);
    const loading = ref(true);
    const addOrEdit = ref("");
    const dialogData = ref(null);
    const maintainSignatureDialog = ref(null);
    const isActive = ref(["Yes", "No"]);
    const icon = ref(true);
    let noDataAvailableText = ref(" ");
    let headers = ref([
      {
        title: "Railroad Name",
        filter: null,
        sortType: "text",
        filterType: "textbox",
        filterWith: "includes",
        key: "name",
        isLink: true,
        columnType: "icon",
        textWithIcon: true,
        icon: "flagIcon",
        justifyContent: "start",
        columnWidth: "250px",
      },
      {
        title: "Registered Business Partner",
        sortType: "text",
        key: "isBusinessPartner",
        isYesNoIcon: true,
        columnType: "icon",
        columnWidth: "50px",
      },
      {
        title: "FID",
        sortType: "text",
        key: "federalId",
        columnWidth: "50px",
      },
      {
        title: "SAP Vendor#",
        sortType: "text",
        key: "sapVendorId",
      },
      {
        title: "Active",
        sortType: "text",
        filter: [],
        filterType: "multi-select",
        key: "isActive",
        columnWidth: "5%",
        fixedWidth: true,
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "name",
      order: "asc",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const { getFormattedDateStringNoTime } = useDateField();
    const initialLoad = ref(true);
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const allSignatureList = ref([]);
    const isRailroadCompaniesManageAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    onMounted(async () => {
      loading.value = true;
      await getRailroadCompaniesUpdateAccess();
      if (isRailroadCompaniesManageAccess.value.read) {
        await fetchData();
      }
      loading.value = false;
    });
    const getRailroadCompaniesUpdateAccess = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.MANAGE_RAILROAD_COMPANIES_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.MANAGE_RAILROAD_COMPANIES_CRTUPD_ACCESS
      );
      isRailroadCompaniesManageAccess.value.read = readResponse || false;
      isRailroadCompaniesManageAccess.value.update = updateResponse || false;
    };
    async function fetchData() {
      let Filter = {
        name: null,
        isActive: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value?.length) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText = "";
      if (sortData.value) {
        filterText =
          filterText +
          "?sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      try {
        loading.value = true;
        const response = await axios.post(
          CONFIG_ENDPOINT.GET_RAILROAD_LIST + filterText,
          Filter
        );
        if (!initialLoad.value) {
          initialLoad.value = true;
          allSignatureList.value = response.data.railRoadAdminDTO;
        }
        railRoadList.value = response.data.railRoadAdminDTO;
        railRoadList.value.forEach((item) => {
          let icons = "";
          item?.projectIdentifier?.forEach((icon) => {
            icons = icons + getIdentifierIcon(icon);
          });
          item.projectIdentifierIcon = icons;
          item.letDate = getDate(item.letDate);
          item.flagIcon = getFlagIcon(item.yellowFlagIcon);
        });
        lookupData.value = response.data.railRoadLookupDTO;
        headers.value.forEach((header, index) => {
          headers.value[index] = {
            ...header,
            sortable: !checkFieldDisabled(header.key, header?.filterType),
          };
        });
        noDataAvailableText.value = "No Data Available";
      } catch (err) {
        railRoadList.value = [];
        paginationData.value = {
          page: 1,
          items: paginationData.value.items,
          total: 0,
        };
        noDataAvailableText.value = "No Data Available";
      } finally {
        loading.value = false;
        noDataAvailableText.value = "No Data Available";
      }
    }
    const getFlagIcon = (icon) => {
      if (icon) {
        return "<i class='tableIcons fas fa-flag' aria-hidden='true' style='color: #F8A311;'></i>";
      } else {
        return "<i class='tableIcons fas fa-' aria-hidden='true' style='color: #F8A311;visibility:hidden'></i>";
      }
    };
    const checkFieldDisabled = (key, filterType) => {
      if (
        key === "isBusinessPartner" ||
        key === "federalId" ||
        key === "sapVendorId"
      ) {
        return true;
      }
      if (filterType != "auto-complete" || filterType != "multi-select") {
        return false;
      }
      return !lookupData.value[key]?.length;
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const addButtonClicked = () => {
      window.scrollTo(0, 0);
      router.push({
        path: `/GCMS/manage-railroad-companies/add`,
      });
    };
    const editButtonClicked = (event) => {
      router.push({
        path:
          `/GCMS/manage-railroad-companies/edit/` +
          railRoadList.value[event.index]?.railroadCompanyId,
      });
    };
    const openDialog = (dialogType) => {
      maintainSignatureDialog.value
        .open(
          dialogType + " Signature Template",
          dialogData.value,
          dialogType,
          allSignatureList.value
        )
        .then((resolve) => {
          fetchData();
        });
    };
    const textFilterChange = (event) => {
      let index = textFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };
    return {
      railRoadList,
      loading,
      headers,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      paginationData,
      textFilterChange,
      clearAllFilter,
      editButtonClicked,
      multipleFiltersChange,
      multipleFilter,
      lookupData,
      addOrEdit,
      addButtonClicked,
      maintainSignatureDialog,
      AUTO_COMPLETE_NO_DATA,
      DROPDOWN_NO_DATA,
      isActive,
      icon,
      openDialog,
      isRailroadCompaniesManageAccess,
      noDataAvailableText,
    };
  },
};
