<template>
  <BannerMessage
    v-if="showSaveBannerMessage"
    :successBanner="true"
    :message="'Successfully Saved Estimate form.'"
    @hideBanner="hideBanner"
  ></BannerMessage>
  <BannerMessage
    v-if="showAcceptBannerMessage"
    :successBanner="true"
    :message="'Successfully submitted the Estimate form for review'"
    @hideBanner="hideBanner"
  ></BannerMessage>
  <BannerMessage
    v-if="showTotalErrorBannerMessage"
    :message="'At least one line item cost is required to Submit the form'"
    @hideBanner="hideBanner"
  ></BannerMessage>
  <BannerMessage
    v-if="showAcceptErrorMessage"
    :errorBanner="true"
    :message="'At least one line item cost is required to Accept the form'"
    @hideBanner="hideBanner"
  ></BannerMessage>
  <BannerMessage
    v-if="showAcceptErrorAgreementMessage"
    :errorBanner="true"
    :message="'Agreement number required to Accept the form'"
    @hideBanner="hideBanner"
  ></BannerMessage>
  <BannerMessage
    v-if="showAgreementErrorBannerMessage"
    :errorBanner="true"
    :message="'Agreement number already exists'"
    @hideBanner="hideBanner"
  ></BannerMessage>
  <BannerMessage
    v-if="businessMessageError"
    :errorBanner="true"
    :message="businessErrorMessage"
    @hideBanner="hideBanner"
  ></BannerMessage>
  <v-row>
    <!-- Left corner element -->
    <v-col class="ml-20">
      <router-link :to="`/GCMS/${id}/railroad-estimates`">
        &lt; Return to Estimate list
      </router-link>
    </v-col>
    <!-- Centered element -->
    <v-col class="page-title">
      <h1>Railroad Estimate</h1>
    </v-col>
    <v-col> </v-col>
  </v-row>
  <div>
    <v-form v-model="isFormValid" class="projectInvolvement">
      <div>
        <div class="env-header mb-20 railroadEstimateSubHeader">
          <h2 class="locationHeaderText">Railroad Details</h2>
          <h2 class="locationHeaderText railroadStatus">
            Status: {{ formStatus }}
          </h2>
        </div>
      </div>
      <div class="w-100">
        <v-row class="m-20 estimateRow">
          <v-col cols="2">Railroad: <label class="asterisk">*</label> </v-col>
          <v-col cols="3" v-if="staticRailroad">
            <label>
              {{ selectedRailroad }}
            </label>
          </v-col>
          <v-col cols="3" v-else>
            <v-select
              :items="railroadList"
              v-model="selectedRailroad"
              :rules="[requiredRule]"

            ></v-select>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="2">SAP Vendor #: </v-col>
          <v-col cols="1">
            {{ selectedRailroadDetails?.sapVendorId }}
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="1">FID #: </v-col>
          <v-col cols="1">
            {{ selectedRailroadDetails?.federalId }}
          </v-col>
        </v-row>

        <v-row class="m-20 estimateRow">
          <v-col cols="2">Corporate Address: </v-col>
          <v-col cols="3">
            {{ selectedRailroadDetails?.corporateAddress }}
          </v-col>
        </v-row>

        <v-row class="m-20 estimateRow">
          <v-col cols="2"
            >Billing Type: <label class="asterisk"> *</label>
          </v-col>
          <v-col cols="3">
            <v-radio-group
              class="alignRadioButton"
              inline
              :rules="[requiredRule]"
              v-model="billingType"
              :disabled="!isUserEstimateAccess.update"
            >
              <v-radio
                label="Cost estimate for Actual"
                value="Cost estimate for Actual"
                :disabled="!isUserEstimateAccess.update"

              ></v-radio>
              <v-radio
                label="Lump Sum"
                value="Lump Sum"
                :disabled="!isUserEstimateAccess.update"

              ></v-radio> </v-radio-group
          ></v-col>
        </v-row>

        <v-row class="m-20 estimateRow">
          <v-col cols="2" class="agreementType"
            >Agreement Type: <label class="asterisk">*</label>
          </v-col>
          <v-col cols="4">
            <v-radio-group
              class="alignRadioButton"
              inline
              :rules="[requiredRule]"
              v-model="agreementType"
              :disabled="!isUserEstimateAccess.update"

            >
              <v-radio
                label="Preliminary Engineering"
                value="Preliminary Engineering"
                :disabled="!isUserEstimateAccess.update"

              ></v-radio>
              <v-radio label="Construction" value="Construction"></v-radio>
              <v-radio
                label="Combination"
                value="Combination"
                :disabled="!isUserEstimateAccess.update"

              ></v-radio> </v-radio-group
          ></v-col>
        </v-row>

        <v-row class="m-20 estimateRow">
          <v-col cols="2">Project Type: </v-col>
          <v-col cols="3">
            {{ projectType }}
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="2">Agreement #: </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="agreement"
              :rules="[agreementLengthRule]"
              :disabled="!isUserEstimateAccess.update"

            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <br /><br />
      <div>
        <div class="env-header mb-20">
          <h2 class="locationHeaderText">Estimated Costs</h2>
        </div>
      </div>

      <div v-for="(index, estimatedCost) in estimatedCosts" :key="index">
        <div v-if="Object.keys(index.valuePair).length != 0">
          <v-row class="estimateRowSubHeader">
            <v-col cols="3">
              <label class="impactedCrossingHeader">
                {{ index.displayName }}</label
              ></v-col
            >
          </v-row>

          <div v-for="childIndex in index.valuePair" :key="childIndex">
            <div v-if="Object.keys(childIndex.valuePair).length != 0">
              <v-row class="m-20 estimateCostRow">
                <v-col cols="4">
                  <b>{{ childIndex.displayName }}</b></v-col
                >
              </v-row>

              <div
                v-for="grandChildIndex in childIndex.valuePair"
                :key="grandChildIndex"
              >
                <v-row class="m-20 estimateCostRow">
                  <v-col cols="4">
                    <label>{{ grandChildIndex.displayName }}</label></v-col
                  >
                  <v-col cols="2">
                    <CurrencyInput
                      class="estimateTextField"
                      v-model="grandChildIndex.value"
                      maxlength="12"
                      :rules="[railroadEstimateRule]"
                      :disabled="!isUserEstimateAccess.update"

                    ></CurrencyInput>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div v-else-if="isFinite(childIndex.displayName.split(' ')[0][0])">
              <v-row class="m-20 estimateCostRow">
                <v-col cols="4">
                  <label>{{ childIndex.displayName }}</label></v-col
                >
                <v-col cols="2">
                  <CurrencyInput
                    class="estimateTextField"
                    v-model="childIndex.value"
                    maxlength="12"
                    :rules="[railroadEstimateRule]"
                    :disabled="!isUserEstimateAccess.update"

                  ></CurrencyInput>
                </v-col>
              </v-row>
            </div>

            <div
              v-else-if="
                childIndex.displayName == 'Total Estimated Railroad Costs'
              "
            >
              <br />
              <v-row class="m-20 estimateCostTotalRow">
                <v-col cols="4"></v-col>
                <v-col cols="3">
                  <label class="impactedCrossingHeader">{{
                    childIndex.displayName
                  }}</label></v-col
                >
                <v-col cols="2">
                  <b class="impactedCrossingHeader">{{
                    totalEstimatedRailroadCosts
                  }}</b>
                </v-col>
              </v-row>
            </div>

            <div v-else-if="childIndex.displayName.split(' ')[0] == 'Total'">
              <br />
              <v-row class="m-20 estimateCostTotalRow">
                <v-col cols="4"></v-col>
                <v-col cols="3">
                  <label>{{ childIndex.displayName }}</label></v-col
                >
                <v-col cols="2">
                  <b class="estimateTextField">{{
                    totalMap[index.displayName]
                  }}</b>
                </v-col>
              </v-row>
            </div>

            <div v-else>
              <br /><br />
              <v-row cols="12" class="m-20 estimateCostTotalRow">
                <v-col cols="4"></v-col>
                <v-col cols="3">
                  <label>{{ childIndex.displayName }}</label></v-col
                >
                <v-col
                  cols="3"
                  v-if="childIndex.displayName == 'PennDOT Share'"
                >
                  <b class="estimateTextField2">{{ pennDOTShare || 0 }}</b>
                </v-col>
                <v-col cols="2" v-else>
                  <CurrencyInput
                    class="estimateTextField"
                    v-model="childIndex.value"
                    maxlength="12"
                    :rules="[railroadEstimateRule]"
                    :disabled="!isUserEstimateAccess.update"

                  ></CurrencyInput>
                </v-col>
              </v-row>
            </div>
          </div>

          <br /><br /><br /><br />
        </div>

        <div v-else>
          <v-row class="estimateRowSubHeader">
            <v-col cols="7">
              <label class="impactedCrossingHeader">
                {{ index.displayName }}</label
              ></v-col
            >
            <v-col cols="2" v-if="index.displayName.split(' ')[1] != 'TOTAL'">
              <CurrencyInput
                class="estimateTextField"
                v-model="index.value"
                maxlength="12"
                :rules="[railroadEstimateRule]"
                :disabled="!isUserEstimateAccess.update"

              ></CurrencyInput>
            </v-col>

            <v-col cols="2" v-else>
              <b class="impactedCrossingHeader"> {{ pennDOTShare }}</b>
            </v-col>
          </v-row>
          <br /><br />
        </div>
      </div>
      <div class="w-100 d-flex justify-content-end mt-20 mb-20">
        <PrimaryButton
          @buttonClicked="saveClicked"
          :buttonText="'Save'"
          class="ml-10"
          :disabled="!valueChanged || !isUserEstimateAccess.update"
        ></PrimaryButton>
        <SecondaryButton
          @buttonClicked="cancelSave"
          :buttonText="'Cancel'"
          class="ml-10"
          :disabled="!isUserEstimateAccess.update"
        ></SecondaryButton>
        <PrimaryButton
          :buttonText="'Submit'"
          @buttonClicked="submitClicked"
          class="ml-10"
          :disabled="!isFormValid || !isUserEstimateAccess.update || isUserEstimateAccess.accept"
        ></PrimaryButton>
        <PrimaryButton
          @buttonClicked="acceptClicked"
          :buttonText="'Accept'"
          :disabled="!(formStatus == 'Draft' || formStatus == 'Submitted') || !isUserEstimateAccess.accept || !agreement || !isFormValid"
          class="ml-10"
        ></PrimaryButton>
        <PrimaryButton
          @buttonClicked="saveClicked"
          :buttonText="'Return'"
          :disabled="true"
          class="ml-10"
        ></PrimaryButton>
      </div>
    </v-form>


      <br /><br />
      <div>
        <div class="env-header mb-20">
          <h2 class="locationHeaderText">Estimate Documents</h2>
        </div>
        <v-row cols="12">
          <v-col cols="12">
            <div class="view-upload-section">
              <span
                class="color-blue underline cursor-pointer"
                @click="otherButtonClicked()"
                >Upload Estimate Document</span
              >
            </div>
          </v-col>
        </v-row>
        <v-grid>
          <v-row justify="center">
            <v-col cols="11">
              <DocumentsList
                :heading="'Railroad Estimates Document List'"
                :doctype="'/RR_EST_VW'"
                :id="id"
                :updateDocumentList="updateDocumentList"
                @updateDocumentListFlag="updateDocumentListFlag"
                @otherButtonClicked="updateButtonClicked($event)"
              >
              </DocumentsList>
            </v-col>
          </v-row>
          <br><br>
        </v-grid>
      </div>

      
    <DialogComponent ref="dialogComponent"></DialogComponent>
    <DialogComponent
      ref="railroadEstimatesDocumentsDialogRef"
    ></DialogComponent>
  </div>
  <div class="hiddenReviewPDF" v-if="estimatedCosts" style="display:None !important;">
    <!DOCTYPE html>
    <html lang="en" id="pdf">
      <head>
        <title style="text-align: center">Railroad Estimate</title>
      </head>
      <body>
        <div>
          <img
            :src="PennDotImageDataURL"
            alt="PennDot Logo"
            style="width: 200px; height: 50px"
          />
          <h1 style="text-align: center">Railroad Estimate</h1>
        </div>

        <div>
          <label style="font-weight: bolder"
            >MPMS:
            <label style="font-weight: 400">{{
              projectDetails?.mpmsNumber
            }}</label></label
          >
        </div>
        <br />
        <div style="display: flex; justify-content: space-between">
          <label style="font-weight: bolder"
            >SR-SEC:
            <label style="font-weight: 400"
              >{{ projectDetails?.stateRoute }}-{{
                projectDetails?.srSection
              }}</label
            ></label
          >
          <label style="font-weight: bolder"
            >County:
            <label style="font-weight: 400">{{
              projectDetails?.county
            }}</label></label
          >
          <label style="font-weight: bolder"
            >Municipality:
            <label style="font-weight: 400">{{
              projectDetails?.municipality
            }}</label></label
          >
        </div>
        <br />
        <div style="display: flex; justify-content: space-between">
          <label
            style="padding-right: 10px; font-size: 20px; font-weight: bolder"
          >
            Railroad Estimate Details</label
          >
          <br />
          <label style="font-size: 20px; font-weight: bolder">
            Status: Accepted</label
          >
        </div>
        <div style="background-color: #000000; height: 2px; width: 100%"></div>
        <br />
        <div>
          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >Agreement #:
              <label style="font-weight: 400">{{ agreement }}</label></label
            >
            <br />
            <label style="font-weight: bolder"
              >Agreement Type:
              <label style="font-weight: 400"
                >{{ agreementType }}
              </label></label
            >
          </div>
          <br />
          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >Railroad Name:
              <label style="font-weight: 400">{{
                selectedRailroad
              }}</label></label
            >
          </div>
          <br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >Corporate Address:
              <label style="font-weight: 400">{{
                selectedRailroadDetails?.corporateAddress
              }}</label></label
            >
          </div>
          <br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >SAP Vendor:
              <label style="font-weight: 400">{{
                selectedRailroadDetails?.sapVendorId
              }}</label></label
            >
            <br />
            <label style="font-weight: bolder"
              >FID:
              <label style="font-weight: 400">{{
                selectedRailroadDetails?.federalId
              }}</label></label
            >
          </div>
          <br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >Billing Type:
              <label style="font-weight: 400">{{ billingType }}</label></label
            >
          </div>
          <br />
          <br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >A. PRELIMINARY ENGINEERING:
            </label>
            <br />
            <label
              >{{
                estimatedCosts2['A. PRELIMINARY ENGINEERING']?.value || '$0.00'
              }}</label
            >
          </div>

          <br /><br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >B. CONSTRUCTION ENGINEERING:
            </label>
            <br />
            <label
              >{{
                estimatedCosts2['B. CONSTRUCTION ENGINEERING']?.value || '$0.00'
              }}</label
            >
          </div>

          <br /><br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >C. CONSTRUCTION - SAFETY WARNING DEVICES:
            </label>
            <br />
            <label
              >{{
                estimatedCosts2['C. CONSTRUCTION  -  SAFETY WARNING DEVICES']?.value || '$0.00'
              }}</label
            >
          </div>
          <br /><br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >D. CONSTRUCTION - CROSSING SURFACE:
            </label>
            <br />
            <label
              >{{
                estimatedCosts2['D. CONSTRUCTION  -  CROSSING SURFACE']?.value ||'$0.00'
              }}</label
            >
          </div>
          <br /><br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >E. CONSTRUCTION - HIGHWAY & BRIDGE:
            </label>
            <br />
            <label
              >{{
                estimatedCosts2['E. CONSTRUCTION - HIGHWAY & BRIDGE']?.value || '$0.00'
              }}</label
            >
          </div>
          <br /><br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder">F. PROTECTIVE SERVICES: </label>
            <br />
            <label
              >{{
                estimatedCosts2['F. PROTECTIVE SERVICES']?.value || '$0.00'
              }}</label
            >
          </div>
          <br /><br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >G. ADMINISTRATION, INDIRECT OVERHEAD & SUPERVISION:
            </label>
            <br />
            <label
              >{{
                estimatedCosts2[
                  'G. ADMINSTRATION, INDIRECT OVERHEAD & SUPERVISION'
                ]?.value || '$0.00'
              }}</label
            >
          </div>
          <br /><br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder">H. CONTINGENCY: </label>
            <br />
            <label style="display: flex; justify-content: end"
              >{{ estimatedCosts2['H. CONTINGENCY']?.value ||'$0.00' }}</label
            >
          </div>
          <br />
          <br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >I. TOTAL ESTIMATED RAILROAD COSTS
            </label>
            <br />
          </div>
          <br />
          <div style="padding-left: 20px">
            <label>LESS CREDITS </label>
            <br />
          </div>
          <br />

          <div style="padding-left: 20px">
            <label style="padding-right: 105px">1. Betterment: </label>
            <label>$1000.00 </label>
          </div>

          <div style="padding-left: 20px">
            <label style="padding-right: 130px">2. Salvage </label>
            <label
              >{{
                estimatedCosts2['I. TOTAL ESTIMATED RAILROAD COSTS']?.valuePair[
                'Less Credits'
                ]?.valuePair['1. Salvage']?.value || '$0.00'
              }}</label
            >
          </div>
          <div style="padding-left: 20px">
            <label style="padding-right: 57px">3. Expired Service Life</label>
            <label style="margin-left:-4px;">$0.00</label>
          </div>
          <div style="padding-left: 20px">
            <label style="padding-right: 92px">4. Miscellaneous</label>
            <label
              >{{
                estimatedCosts2['I. TOTAL ESTIMATED RAILROAD COSTS']?.valuePair[
                  'Less Credits'
                ]?.valuePair['2. Miscellaneous']?.value ||'$0.00'
              }}</label
            >
          </div>
          <br />
          <div style="display: flex; justify-content: end">
            <label style="padding-right: 10px"
              >Total Estimated Railroad Costs:
            </label>
            <label>{{ totalEstimatedRailroadCosts }}</label>
            <br />
          </div>
          <br />

          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder">J. TOTAL PROJECT COSTS</label>
            <br />
          </div>
          <br />
          <div style="display: flex; justify-content: end">
            <label style="padding-right: 10px">PennDOT Share: </label>
            <label>{{ pennDOTShare }}</label>
            <br />
          </div>
          <br />
          <div style="display: flex; justify-content: end">
            <label style="padding-right: 10px">Railroad Share: </label>
            <label>{{ estimatedCosts2['J. TOTAL PROJECT COSTS']?.valuePair['Railroad Share']?.value  || '$0.00'}}</label>
            <br />
          </div>
          <br />

          <br />
          <br />
          <div style="display: flex; justify-content: space-between">
            <label style="font-weight: bolder"
              >K: TOTAL RAILROAD REIMBURSEMENT
            </label>
            <br />
            <label style="display: flex; justify-content: end">
              {{ pennDOTShare }}</label
            >
          </div>
          <br />
        </div>
      </body>
    </html>
  </div>
</template>
  <script>
import EditRailroadEstimates from "@/modules/manageproject/scripts/EditRailroadEstimates.js";
export default EditRailroadEstimates;
</script>
  
  <style>
@import "@/assets/css/style.scss";
@import "@/modules/manageproject/assets/railroadEstimates.scss";
</style>
  