<template>
  <v-card>
    <OnlineHelpPOC :helpKey="'PRJADSRCH'"></OnlineHelpPOC>
    <div class="page-title allProjectsHeading">
      <h1>Advanced Search</h1>
    </div>
    <v-card-text>
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-title class="pannelColor">
            <div class="panel-header">
              Search Criteria
            </div></v-expansion-panel-title
          >

          <v-expansion-panel-text class="v-card-text">
            <div class="env-header mb-20">
              <h2 class="locationHeaderText">Location</h2>
            </div>
            <div class="env-infp">
              <div class="env-content">
                <v-row class="center">
                  <v-col cols="4">
                    <label class="mpms-env-label">
                      <v-row class="searchRow">
                        <v-col cols="3">
                          <b>District: </b>
                        </v-col>
                        <v-col cols="5">
                          <v-select
                            class="select-fields"
                            outlined
                            multiple
                            v-model="advancedSearch.districts"
                            :items="lookupData.districts"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                  <v-col cols="4">
                    <label class="mpms-env-label">
                      <v-row class="searchRow">
                        <v-col cols="3">
                          <b>County: </b>
                        </v-col>
                        <v-col cols="6">
                          <v-chip
                            v-for="(item, index) in advancedSearch.counties"
                            :key="item"
                            class="ma-2"
                            closable
                            @click:close="removeCounty(index)"
                          >
                            {{ item }}
                          </v-chip>

                          <VueAutoComplete
                            v-if="advancedSearch.districts != 0"
                            class="select-fields"
                            outlined
                            multiple
                            v-model="advancedSearch.counties"
                            :items="countyList"
                            item-title="name"
                            item-value="name"
                          ></VueAutoComplete>
                          <VueAutoComplete
                            v-else
                            class="select-fields"
                            outlined
                            multiple
                            v-model="advancedSearch.counties"
                            :items="countyList"
                            item-title="countyName"
                            item-value="countyName"
                          ></VueAutoComplete>
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                  <v-col cols="4">
                    <label class="mpms-env-label">
                      <v-row class="searchRow">
                        <v-col cols="3">
                          <b>Municipality: </b>
                        </v-col>
                        <v-col cols="78">
                          <v-chip
                            v-for="(item, index) in advancedSearch.municipality"
                            :key="item"
                            class="ma-2"
                            closable
                            @click:close="removeMunicipality(index)"
                          >
                            {{ item }}
                          </v-chip>
                          <VueAutoComplete
                            class="select-fields"
                            outlined
                            multiple
                            v-model="advancedSearch.municipality"
                            :items="municipalityList"
                            item-title="municipalityName"
                            item-value="municipalityName"
                            :disabled="advancedSearch.counties.length == 0"
                          ></VueAutoComplete>
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                  <v-col cols="4">
                    <label class="mpms-env-label">
                      <v-row class="searchRow">
                        <v-col cols="3">
                          <b>SR: </b>
                        </v-col>
                        <v-col cols="5">
                          <v-select
                            class="select-fields"
                            outlined
                            v-model="advancedSearch.sr"
                            :items="lookupData.stateRoutes"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                  <v-col cols="4">
                    <label class="mpms-env-label">
                      <v-row class="searchRow">
                        <v-col cols="3">
                          <b>Sec: </b>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            outlined
                            :maxlength="3"
                            v-model="advancedSearch.sec"
                            @keydown="allowAlphanumericOnly($event)"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                  <v-col cols="4">
                    <label class="mpms-env-label">
                      <v-row class="searchRow">
                        <v-col cols="3">
                          <b>Segment: </b>
                        </v-col>
                        <v-col
                          cols="4"
                          v-if="
                            advancedSearch.counties.length != 0 &&
                            advancedSearch.sr != null
                          "
                        >
                          <v-select
                            class="select-fields"
                            outlined
                            v-model="advancedSearch.segment1"
                            :items="lookupData.segment1"
                          ></v-select>
                        </v-col>
                        <v-col cols="4" v-else>
                          <v-select
                            class="select-fields"
                            outlined
                            disabled
                          ></v-select>
                        </v-col>
                        <v-col cols="1"></v-col>
                        <v-col cols="4" v-if="advancedSearch.segment1 != null">
                          <v-select
                            class="select-fields"
                            outlined
                            v-model="advancedSearch.segment2"
                            :items="lookupData.segment2"
                          ></v-select>
                        </v-col>
                        <v-col cols="4" v-else>
                          <v-select
                            class="select-fields"
                            outlined
                            disabled
                          ></v-select>
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                  <v-col cols="4">
                    <label class="mpms-env-label">
                      <v-row class="searchRow">
                        <v-col cols="3">
                          <b>Road Name: </b>
                        </v-col>
                        <v-col cols="5">
                          <v-autocomplete
                            v-model:search="queryRoad"
                            v-model="advancedSearch.road"
                            @update:search="autoComplete('street')"
                            :items="autoCompleteLookup.roads"
                            :no-data-text="AUTO_COMPLETE_NO_DATA"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="env-header mb-20">
              <h2 class="locationHeaderText">Project</h2>
            </div>
            <div class="env-infp">
              <div class="env-content">
                <v-row class="center">
                  <v-col cols="12">
                    <label class="mpms-env-label">
                      <v-row class="searchRow">
                        <v-col cols="4">
                          <label class="mpms-env-label">
                            <v-row>
                              <v-col cols="3">
                                <b>Title: </b>
                              </v-col>
                              <v-col cols="5">
                                <v-autocomplete
                                  v-model:search="queryTitle"
                                  v-model="advancedSearch.title"
                                  @update:search="autoComplete('title')"
                                  :items="autoCompleteLookup.titles"
                                  :no-data-text="AUTO_COMPLETE_NO_DATA"
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </label>
                        </v-col>
                        <v-col cols="4">
                          <label class="mpms-env-label">
                            <v-row class="searchRow">
                              <v-col cols="3">
                                <b>Let Date: </b>
                              </v-col>
                              <v-col cols="4">
                                <label>From</label>
                                <DatePicker
                                  :value="advancedSearch.letDate1"
                                  :maxDate="fromMaxDate"
                                  :minDate="fromMinDate"
                                  :invalidMsg="'From Date'"
                                  @input="dateChange1($event)"
                                  @dateValidation="checkDateValidation($event)"
                                ></DatePicker>
                              </v-col>
                              <v-col cols="4">
                                <label>To</label>
                                <DatePicker
                                  :value="advancedSearch.letDate2"
                                  :maxDate="toMaxDate"
                                  :minDate="toMinDate"
                                  :invalidMsg="'To Date'"
                                  @input="dateChange2($event)"
                                  @dateValidation="checkDateValidation2($event)"
                                ></DatePicker>
                              </v-col>
                            </v-row>
                          </label>
                        </v-col>
                        <v-col cols="4">
                          <label class="mpms-env-label">
                            <v-row class="searchRow">
                              <v-col cols="3">
                                <b>Status: </b>
                              </v-col>
                              <v-col cols="5">
                                <v-select
                                  class="select-fields"
                                  :items="lookupData.status"
                                  multiple
                                  outlined
                                  v-model="advancedSearch.statuses"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </label>
                        </v-col>
                        <v-col cols="4">
                          <label class="mpms-env-label">
                            <v-row class="searchRow">
                              <v-col cols="3">
                                <b>DOT#: </b>
                              </v-col>
                              <v-col cols="5">
                                <v-autocomplete
                                  v-model:search="queryDOT"
                                  v-model="advancedSearch.dotNumber"
                                  @update:search="autoComplete('dotnumber')"
                                  :items="autoCompleteLookup.dotNumbers"
                                  :no-data-text="AUTO_COMPLETE_NO_DATA"
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </label>
                        </v-col>
                        <v-col cols="4">
                          <label class="mpms-env-label">
                            <v-row class="searchRow">
                              <v-col cols="3">
                                <b>PUC Docket#</b>
                              </v-col>
                              <v-col cols="5">
                                <v-autocomplete
                                  v-model:search="queryPUC"
                                  v-model="advancedSearch.pucDocketNumber"
                                  @update:search="
                                    autoComplete('pucDocketNumber')
                                  "
                                  :items="autoCompleteLookup.pucDocketNumbers"
                                  :no-data-text="AUTO_COMPLETE_NO_DATA"
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </label>
                        </v-col>
                        <v-col cols="4"></v-col>
                        <v-col cols="4">
                          <label class="mpms-env-label">
                            <v-row class="searchRow">
                              <v-col cols="3">
                                <b>User: </b>
                              </v-col>
                              <v-col cols="5">
                                <v-autocomplete
                                  :items="[1, 2, 3, 4]"
                                  disabled
                                  :no-data-text="AUTO_COMPLETE_NO_DATA"
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </label>
                        </v-col>
                        <v-col cols="4">
                          <v-row class="searchRow">
                            <v-col cols="3">
                              <b>Safety Project: </b>
                            </v-col>
                            <v-col cols="5">
                              <v-checkbox-btn
                                v-model="advancedSearch.safetyProject"
                              ></v-checkbox-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="4">
                          <v-row class="searchRow">
                            <v-col cols="4">
                              <b
                                ><span
                                  >Highway/Bridge Construction Project:
                                </span></b
                              >
                            </v-col>
                            <v-col cols="5">
                              <v-checkbox-btn
                                v-model="advancedSearch.constructionProject"
                              ></v-checkbox-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="env-header mb-20">
              <h2 class="locationHeaderText">Railroad</h2>
            </div>
            <div class="env-infp">
              <div class="env-content">
                <v-row class="center">
                  <v-col cols="4">
                    <label class="mpms-env-label">
                      <v-row class="searchRow">
                        <v-col cols="3">
                          <b>Railroad Name: </b>
                        </v-col>
                        <v-col cols="5">
                          <v-autocomplete
                            v-model:search="queryRailroad"
                            v-model="advancedSearch.railroad"
                            @update:search="autoComplete('railroadName')"
                            :items="autoCompleteLookup.railroads"
                            :no-data-text="AUTO_COMPLETE_NO_DATA"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                  <v-col cols="4">
                    <label class="mpms-env-label">
                      <v-row class="searchRow">
                        <v-col cols="3">
                          <b>Agreement# </b>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            outlined
                            disabled
                            v-model="advancedSearch.agreementNumber"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                </v-row>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <PrimaryButton
                  :buttonText="'Search'"
                  @buttonClicked="search"
                  :disabled="
                    enableSearch ||
                    (advancedSearch.letDate1 && !isDateValid1) ||
                    (advancedSearch.letDate2 && !isDateValid2)
                  "
                ></PrimaryButton>
                <SecondaryButton
                  :buttonText="'Clear'"
                  @buttonClicked="clear"
                  class="ml-10"
                ></SecondaryButton>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels> </v-card-text
  ></v-card>
  <div v-if="searchClicked">
    <v-card class="p-64 mb-50">
      <FeatureTable
        :headers="headers"
        :items="searchedProjects"
        :hideFilter="true"
        :showExportButton="true"
        :exportFilName="'Advanced Search'"
        :showClearFilterButton="false"
        :tableHeader="'Search Results: ' + totalProjects + ' Projects'"
        :navigateToMpmsDetails="true"
        :clickable="true"
        :navigationKey="'mpmsNumber'"
        :isDynamicExport="true"
        @updatePaginationPerPage="updatePaginationPerPage"
        @pageChange="pageChange"
        @sortUpdate="sortUpdate"
        :totalItems="paginationData?.total"
        :itemsPerPage="paginationData?.items"
        :linkedPagination="true"
        @exportButtonClicked="exportButtonClicked"
      >
      </FeatureTable>
    </v-card>
  </div>
</template>
<script>
import AdvancedSearch from "@/modules/advance-search/scripts/AdvancedSearch.js";
export default AdvancedSearch;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
