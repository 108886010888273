<template>
  <v-app>
    <v-main v-if="!external">
      <div>
        <HeaderPage /> <GcmsMenu /><router-view  style="padding-bottom: 40px !important;"/>

        <PageFooter />
      </div>
    </v-main>
    <v-main v-else class="external-v-main">
      <div class="main-div">
        <HeaderPage external />
        <ViewDocument
          :mpmsNumber="mpmsNumber"
          :docType="docType"
          :railRoadID="railRoadID"
        />

        <ExternalBanner
          v-if="banner === 'Y' || banner === 'y'"
          class="external-footer"
        />
      </div>
    </v-main>
  </v-app>
</template>
<script>
import ExternalBanner from "@/modules/gcmsmain/gcmsmainpage/components/ExternalBanner.vue";
import GcmsMenu from "@/modules/gcmsmain/gcmsmainpage/components/GcmsMain.vue";
import PageFooter from "@/modules/gcmsmain/gcmsmainpage/components/footerPage.vue";
import HeaderPage from "@/modules/gcmsmain/gcmsmainpage/components/headerPage.vue";
import ViewDocument from "@/modules/shared/components/ViewDocuments-External.vue";
export default {
  name: "App",
  components: {
    PageFooter,
    GcmsMenu,
    HeaderPage,
    ViewDocument,
    ExternalBanner,
  },
  props: {
    external: { type: Boolean, default: false },
    mpmsNumber: String,
    docType: String,
    railRoadID: String,
    banner: String,
  },
};
</script>
<style>
@import "@/assets/css/main.scss";
@import "@/assets/css/color.scss";
@import "@/assets/css/style.scss";
</style>